import React, { createContext, useReducer } from 'react';
import appReducer from './../reducers/app-reducer';
import { isBrowser } from './../../utility';

export const appContext = createContext();

const AppProvider = appContext.Provider;


export function AppWrapper({ children }) {
  const [app, setApp] = useReducer(appReducer, {
    menu: false,
    search: false,
    touch: isBrowser && window.matchMedia("(pointer: coarse)").matches,
  })
  return <AppProvider value={{ app, setApp }}>{ children }</AppProvider>
};
