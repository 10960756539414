import React from 'react';
import { Link } from "gatsby"
import {Box, styled} from '@mui/system';

const Svg = styled('svg', {
  shouldForwardProp: (prop) => prop !== "variant",
})(
  ({ theme, variant }) => ({
    width: '100%',
    height: 'auto',
    '& path': {
      fill: variant === 'negative' ? 'var(--white)' : 'var(--grey2)',
      transition: 'all 0.25s ease-in-out'
    },
    '&:hover path': {
      fill: variant === 'negative' ? 'var(--grey2)' : 'var(--grey3)'
    }
  })
)

export default function Logo(props) {
  const { variant = 'positive'} = props
  return (
    <Box {...props} >
       <Link title={'Piranesi Homepage'} to='/' style={{
          display: 'block',
        }}>
          <Svg variant={variant} viewBox="0 0 1024 100"><path d="M39.45 61.78c-8.04 0-12.2-.42-12.2-.42v24.25H38.2v9.98h-37v-9.98h10.95V13.97l-10.26.69-.56-10.11 9.98-.97c10.39-.97 21.2-1.11 31.32-1.11 16.63 0 30.77 8.59 30.77 29.1 0 18.15-17.33 30.21-33.95 30.21m-.69-47.4H27.25v34.23h8.31c11.92 0 20.93-3.33 20.93-17.74 0-10.66-4.99-16.48-17.73-16.49m90.76 71.24h10.81v-72.2h-10.81V3.3h37v10.12h-10.95v72.2h10.95v9.97h-37v-9.97zm168.52 9.98s-18.71-29.93-23.28-37.97h-8.6v27.99h10.95v9.98h-37.14v-9.98h10.95V13.97l-10.12.69-.83-9.98 9.98-.7c9.28-.69 18.57-1.53 27.99-1.53 18.99 0 34.23 8.04 34.23 27.44 0 12.47-8.31 22.31-19.96 25.78A319.39 319.39 0 0 0 311.2 85.6h8.31v9.98l-21.47.02zm-22.31-80.8h-9.56v29.93h9.7c14.97 0 19.68-4.99 19.68-15.52 0-9.97-5.82-14.41-19.82-14.41m156.46 70.82h7.34l-5.4-15.8h-30.21l-5.54 15.8h6.93v9.98h-27.44v-9.98h6.37L416.67.25h7.21l30.77 85.37h5.96v9.98H432.2l-.01-9.98zm-24.11-27.58h21.76l-9.28-28.41-1.11-4.02-1.11 4.02-10.26 28.41zM610.96 95.6H595.3l-47.12-69.15v59.17h10.95v9.98h-36.59v-9.98h10.95v-72.2h-10.95V3.3h26.47l47.53 69.57V13.42H585.6V3.3h36.31v10.12h-10.95V95.6zm141.63-70.82 1.8-9.56h-26.47l-5.68 27.72h24.39l1.53-7.62h9.28l-5.41 26.75h-9.28l1.39-7.34h-24.25l-5.96 29.1h29.66l2.08-9.56h12.2l-4.3 21.34h-68.18l2.21-9.98h10.67l14.69-72.34h-10.94l2.08-9.84h64.99l-4.3 21.34-12.2-.01zm137.34.28 1.66-7.9a32.638 32.638 0 0 0-10.53-1.94c-9.84 0-17.74 2.91-17.74 11.5v.55c0 5.54 6.93 10.67 13.58 15.94 8.04 6.24 18.01 14 18.01 24.67v1.53c0 11.64-14.27 27.99-36.87 27.99a94.83 94.83 0 0 1-25.22-3.74l3.88-18.71h12.33l-1.8 7.62c3.74 1.11 7.61 1.72 11.5 1.8 16.63 0 20.09-8.73 20.09-12.33v-.69c0-5.27-7.21-11.09-13.72-16.49-7.9-6.51-17.74-13.72-17.74-24.53v-1.67c0-12.47 14.13-26.61 34.92-26.61 11.64 0 23.56 4.44 23.56 4.44l-3.74 18.57h-12.17zm119.04-11.65-14.41 72.2h10.81l-2.08 9.98h-37l2.08-9.98h10.81l14.55-72.2h-10.81L985 3.3h37l-2.08 10.12h-10.95z"/></Svg> 
        </Link>
    </Box>
   
  )
};
