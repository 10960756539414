import React, {useState, useContext} from 'react';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';

import { MenuExpend, MenuLess } from '../icons';

import { appContext } from './../../contexts';
import { closeMenu, openMenu } from './../../contexts/actions';

import { Link } from 'gatsby';



const menuItemStyle = {
  fontWeight: 'bold',
  fontSize: '1rem',
  margin: 0,
  fontFamily: 'var(--nexus-type)',
  color: 'var(--green4)',

}
const subMenuItemStyle = {
  fontWeight: 'normal',
  fontSize: '1rem',
  margin: 0,
  fontFamily: 'var(--nexus-type)',
  color: 'var(--green4)',
  paddingLeft: '1rem'

}

const CollapsableListItem = ({label, subItems, menuItemClickHandle, accordionClickHandle, activeAccordion}) => {
  const open = activeAccordion === label

  return (
    <>
      <ListItemButton aria-label={label} onClick={()=>{
        accordionClickHandle(open ? false : label)
      }}>
        <ListItemText primaryTypographyProps={{sx: menuItemStyle}} primary={label} />
        {open ? <MenuLess /> : <MenuExpend />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit >
        <List component="div" disablePadding>
          {subItems.map(({label, to}) => (
            <Link to={to} onClick={menuItemClickHandle} key={label}>
              <ListItemButton aria-label={label} sx={{ pl: 4 }}>
                <ListItemText primaryTypographyProps={{sx: subMenuItemStyle}} primary={label} />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
    
  )
}

export default function MenuMobile({menuContent}) {
  
  const {app, setApp} = useContext(appContext)
  const [accordion, setAccordion] = useState(false)

  const menuItemClickHandle = () => {
    setTimeout(() => setApp(closeMenu()), 250)
  }

  return (
    <SwipeableDrawer
      anchor={'right'}
      open={app.menu}
      elevation={0}
      onClose={() => setApp(closeMenu())}
      onOpen={() => setApp(openMenu())}
      sx = { (theme) => ({
        '& .MuiDrawer-paper': {
          width: '300px',
          backgroundColor: 'var(--green1)'
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent'
        }
      })}
    >
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {menuContent.map((item) => (
        (item.type === 'single') ? (
          <Link  to={`${item.to}`} onClick={()=>setTimeout(() => setApp(closeMenu()), 250)} key={item.label}>
            <ListItemButton aria-label={item.label}>
              <ListItemText primaryTypographyProps={{sx: menuItemStyle}} primary={item.label} />  
            </ListItemButton>
          </Link>
        ) : (
          <CollapsableListItem 
            key={item.label} 
            label={item.label} 
            subItems={item.items} 
            menuItemClickHandle={menuItemClickHandle}
            accordionClickHandle={(label) => setAccordion(label)}
            activeAccordion={accordion}
          />
        )
      ))}
    </List>   
  </SwipeableDrawer>
  )
};
