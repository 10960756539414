import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { SearchIcon } from './SearchBtn';

import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';


const SearchInput = styled(InputBase)(({ theme }) => (`
  font-family: --var(--nexus-type);
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  background-color: var(--green1);
  color: var(--white);
  padding: 0 ${theme.spacing(4)};
  padding-top: ${theme.spacing(1)};
  transition: all 0.3s ease-in-out;
  border-bottom: solid 4px var(--green1);
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  font-size: var(--fs-l);

  & .MuiInputBase-input {
    line-height: 2;
    height: auto;
  }
`));

const InputAdornment = styled('div')`
  //margin: 0.125em 0.25em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
`;

export default function SearchField({onChange, loading, value, onClose}) {
  return (
    <Box sx = { (theme) => ({
      width: '100%'
    })}>
      <SearchInput
        autoFocus
        endAdornment={
          <InputAdornment sx={{mr:0}}>
            {!loading && 
              <SearchIcon sx = { (theme) => ({
                margin: '8px',
                marginRight: '0.25em',
                fontSize: '1.2rem'
              })}/>
            }
            {loading &&  <CircularProgress color={'secondary'} size={'1.2rem'} sx={{m: 'auto', display: 'block', margin:'0.25em'}}/> }
          </InputAdornment>}
        onChange={ (e) => onChange(e.target.value)}
        value={value}
      />
    </Box>
  );
}