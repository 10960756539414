/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import RootWrapper from './rootWrapper'
import PageWrapper from './page-wrapper'

export const wrapRootElement = RootWrapper
export const wrapPageElement = PageWrapper
