import React from 'react';
import { Box, styled } from '@mui/system';
import { Container } from '../grid';
import Collapse from '@mui/material/Collapse';
import { Link } from 'gatsby';
import Button from '@mui/material/Button';
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import useLocalState from '../utility/hooks';
import { isBrowser } from '../utility';
import Cookies from 'js-cookie';

const Banner = styled(Box)(
  ({ theme }) => `
    background-color: var(--green4);
    font-family: var(--nexus-type);
    padding: ${theme.spacing(3)};
    p {
      color: var(--white);
      font-size: var(--fs-s);
      margin: 0;
      text-align: center;
    }
    a {
      color: var(--green2);
      font-size: var(--fs-s);
    }
    a:hover {
      text-decoration: underline;
    }

    ${theme.breakpoints.up('xxl')} {
      p, a, button {
        font-size: var(--fs-xs);
      }
    }
`)

export default function CookieNotice() {
  
  const location = useLocation();
  if (isBrowser) {
    initializeAndTrack(location);
  }

  const [bannerHidden, setBannerHidden] = useLocalState(
    false,
    'cookieNoticeHidden',
  );

  const enableAnalytics = () => {
    Cookies.set('gatsby-gdpr-google-analytics', true)
    setBannerHidden(true);
  };
  
  return (
  <>
  {isBrowser && 
    <Collapse in={!bannerHidden} mountOnEnter={true} unmountOnExit={true}>
      <Banner>
        <Container>
          <Box sx = { (theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 3,
              flexWrap: 'wrap'
            })}>
            <Box component="p" sx = { (theme) => ({
              width: {
                xs: '100%',
                md: 'auto'
              }
            })}>Help us improve the user experience by using cookies.</Box>
            {/* This site uses cookies. */}
            <Link to='/policy'>Find out more.</Link>
            <Button variant="contained" size="small" onClick={()=> enableAnalytics()}>OKAY. THANKS.</Button>
          </Box>
        </Container>
      </Banner>
    </Collapse>
  }
  </>
  )
};
