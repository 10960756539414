import { createTheme } from "@mui/material";


// let theme = createTheme()

let theme = createTheme({
  mobileMenu: {
    width: '300px'
  },
  spacing: [0, 4, 8, 12, 16, 20, 24, 32, 40, 56, 64, 72, 96, 128, 160].map(px => `${px / 16}rem`),
  palette: {
    primary: {
      main: '#B8D977',
      light: '#8FBF30',
      dark: '#7CA629',
      darkest: '#425916',
    },
    secondary: {
      main: '#7CA629',
    },
    grey0: '#f6f9f9',
    grey1: '#F2F5F5',
    grey2: '#E1E6E6',
    grey3: '#CDD4D4',
    grey4: '#A0A8A8',
    grey5: '#868F8F',
    grey6: '#4D5454',
    white: '#fff',
    black: '#000000',
    error: {
      main: '#ff7b7b',
      light: '#ffecec',


    }
  },
  // Typography
  typography: {
    fontWeightRegular: 400,
    fontFamily: [
        'ff-nexus-mix',
        'sans-serif'
    ].join(','),
   
  },
  // Breakpoint for responsive design
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      sm2: 550,
      md: 720,
      md2: 810,
      lg: 960,
      xl: 1200,
      xxl: 1600,
      xxxl: 1920,
    },
  },
  transitions: {
    easing: {
      easeOutBack: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
      easeOutCubic: 'cubic-bezier(0.33, 1, 0.68, 1)',
      easeOutQuint: 'cubic-bezier(0.22, 1, 0.36, 1)',
      slide: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    duration: {
      enteringScreen: 350,
      leavingScreen: 350
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color:  'var(--grey5)',
          fontFamily: 'var(--nexus-sans)',
          fontSize: 'var(--fs-m)',
          boxShadow: 'none',
          
          ...((
            ownerState.variant === 'fullWidth' ||
            ownerState.variant === 'fullArea' ||
            ownerState.variant === 'contained'
          ) && {
            // Default styles for all buttons
            transition: 'all 0.2s ease-in',
            border: 'none',
            borderRadius: 0,
            color:  'var(--green4)',
            backgroundColor: 'var(--green1)',
            padding: '0.75em 2em',
            lineHeight: '1',
            fontFamily: 'var(--nexus-type)',
            fontWeight: 'bold',
            textTransform: 'none',
            fontSize: 'var(--fs-l)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            textAlign: 'left',

            '& span': {
              display: 'block',
              color: 'var(--white)',
              fontFamily: 'var(--nexus-mix)',
              fontStyle: 'italic',
              lineHeight: '1.25',
              fontWeight: 'normal',
              fontSize: 'var(--fs-m)',
              marginTop: '0.25rem'
            },

            '&:hover': {
              border: 'none',
              backgroundColor: 'var(--green2)',
              borderRadius: 0,
              boxShadow: 'none',
            },
          }),

          ...(ownerState.variant === 'fullWidth' && {
            width: '100%'
          }),
          ...(ownerState.variant === 'fullArea' && {
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '0.75em',
          }),
          ...(ownerState.size === 'small' && {
            fontSize: 'var(--fs-s)'
          }),
          ...(ownerState.size === 'medium' && {
            fontSize: 'var(--fs-m)'
          }),
          ...(ownerState.selected === true && {
            backgroundColor: 'var(--green1)',
            cursor: 'auto',
            '&:hover': {
              backgroundColor: 'var(--green1)',
            }
          }),
          ...(ownerState.selected === false && {
            backgroundColor: 'var(--grey1)',
            color: 'var(--grey4)',
            
            '& span': {
              color: 'var(--grey4)',
              display: 'block',
              fontFamily: 'var(--nexus-mix)',
              fontStyle: 'italic',
              lineHeight: '1.25',
              fontWeight: 'normal',
              fontSize: 'var(--fs-m)',
              marginTop: '0.25rem'
            },

            '&:hover': {
              backgroundColor: 'var(--grey2)',
            }
          }),

          ...(ownerState.disabled === true && {
            color:  'var(--green4) !important',
          }),


        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: 0,
          [theme.breakpoints.up('md2')]: {
            '&:hover': {
              backgroundColor: 'var(--green2)'
            }
          },
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          margin: '0 auto',
        }
      }
    }
  },
});

// console.log(theme)
// console.log(theme.spacing(2), theme.spacing(3), theme.spacing(4))

export default theme;

