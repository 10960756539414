exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-js": () => import("./../../../src/pages/issues.js" /* webpackChunkName: "component---src-pages-issues-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-templates-archive-architects-js": () => import("./../../../src/templates/archive-architects.js" /* webpackChunkName: "component---src-templates-archive-architects-js" */),
  "component---src-templates-archive-categories-js": () => import("./../../../src/templates/archive-categories.js" /* webpackChunkName: "component---src-templates-archive-categories-js" */),
  "component---src-templates-archive-countries-js": () => import("./../../../src/templates/archive-countries.js" /* webpackChunkName: "component---src-templates-archive-countries-js" */),
  "component---src-templates-archive-locations-js": () => import("./../../../src/templates/archive-locations.js" /* webpackChunkName: "component---src-templates-archive-locations-js" */),
  "component---src-templates-single-article-js": () => import("./../../../src/templates/single-article.js" /* webpackChunkName: "component---src-templates-single-article-js" */),
  "component---src-templates-single-event-js": () => import("./../../../src/templates/single-event.js" /* webpackChunkName: "component---src-templates-single-event-js" */),
  "component---src-templates-single-issue-js": () => import("./../../../src/templates/single-issue.js" /* webpackChunkName: "component---src-templates-single-issue-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single-page.js" /* webpackChunkName: "component---src-templates-single-page-js" */)
}

