import React, { useState, createRef, useEffect } from 'react';
import { Box, styled } from '@mui/system';
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';
import { useWindowWidth } from '@react-hook/window-size'

export const CardTitle = styled('h2', {
  shouldForwardProp: (prop) => prop !== "size",
})(
  ({ theme, size }) => {
    let sm, sm2, md, lg;

    switch (size) {
      case ('small'):
        sm = 'var(--fs-m)'
        sm2 = 'var(--fs-l)'
        md = 'var(--fs-m)'
        break;
      case ('medium'):
        sm = 'var(--fs-l)'
        sm2 = 'var(--fs-xxl)'
        md = 'var(--fs-xxl)'
        break;
      case ('normal'):
        sm = 'var(--fs-l)'
        sm2 = 'var(--fs-xl)'
        md = 'var(--fs-xxl)'
        break;
      case ('large'):
        sm = 'var(--fs-l)'
        sm2 = 'var(--fs-xxl)'
        md = 'var(--fs-xxl)'
        lg = 'var(--fs-el)'
        break;
      case ('xlarge'):
        sm = 'var(--fs-l)'
        sm2 = 'var(--fs-xxl)'
        md = 'var(--fs-el)'
        lg = 'var(--fs-eel)'
        break;
      default:
        sm = 'var(--fs-l)'
        sm2 = 'var(--fs-xl)'
        md = 'var(--fs-xl)'
        lg = 'var(--fs-xxl)'
        break;
    }

    return (
      `
      font-family: var(--nexus-mix);
      font-size: ${sm};
      margin: 0;
      font-weight: bold;
      // font-style: italic;
      color: var(--grey5);
      line-height: 1.25;
      
      ${theme.breakpoints.up("sm2")} {
        font-size: ${sm2};
      }
      ${theme.breakpoints.up("md")} {
        font-size: ${md};
      }
      ${theme.breakpoints.up("lg")} {
        font-size: ${lg ? lg : md};
      }
    `
    )
  }
)

export const CardLabel = styled('h3', {
  shouldForwardProp: (prop) => prop !== "size",
})(
  ({ theme, size }) => {
    let md, lg;

    switch (size) {
      case ('medium'):
        md = 'var(--fs-m)'
        break;
      case ('large'):
        md = 'var(--fs-m)'
        lg = 'var(--fs-l)'
        break;
      default:
        md = 'var(--fs-s)'
        break;
    }

    return (`
      font-Family: var(--nexus-type);
      font-size: var(--fs-s);
      margin: 0;
      font-weight: bold;
      color: var(--grey4);

      ${theme.breakpoints.up("md")} {
        font-size: ${md};
      }
      ${theme.breakpoints.up("lg")} {
        font-size: ${lg ? lg : md};
      }

    `)

  }
)

export const CardContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== "variant" || prop !== "grow",
})(
  ({ theme, size, grow = true }) => ({
    padding: `${theme.spacing(2)}`,
    paddingBottom: `${theme.spacing(4)}`,
    flexGrow: grow ? 1 : 0,
  })
)

export const CardImage = ({sx, image, alt = ''}) => {
  const img = getImage(image)
  return(
    <Box sx={sx}>
      <GatsbyImage image={img} alt={alt} />
    </Box>
  )
}

const Wrapper = ({children, direction, to}) => (
  <Box
    sx = { (theme) => ({
      backgroundColor: theme.palette.grey1,
      height: '100%',
      display: 'flex',
      transition: 'all 0.25s ease-in-out',
      flexDirection: {
        xs: 'column',
        md: direction === 'vertical' ? 'column' : 'row',
      },
      '&:hover':{
        backgroundColor: to ? theme.palette.grey2 : theme.palette.white,
      }
    })}
  >
    {children}
  </Box>
)


export const MinHeightCard = ({
  localFile,
  alt,
  to,
  label,
  title, 
  size = "small",
  ratio = 1,
  sx
}) => {
  
  const img = getImage(localFile)
  const imgBox = createRef()
  const vw = useWindowWidth()
  const [minHeight, setMinHeight]  = useState(null)

  // console.log(size)

  useEffect(() => {
    setMinHeight( imgBox.current.clientHeight )
  }, [vw, imgBox])

  return (
    <Card to={to} sx={sx}>
      <Box ref={imgBox}>
        <GatsbyImage image={img} alt={alt} />
      </Box>
      <Box sx = { (theme) => ({
        padding: `${theme.spacing(2)}`,
        paddingBottom: `${theme.spacing(4)}`,
        minHeight: minHeight ? `${minHeight / ratio}px` : `auto`,
      })}>
        { label && <CardLabel>{label}</CardLabel>}
        <CardTitle size={size}>{title}</CardTitle>
      </Box>
    </Card>
  )
}

export const MinHeightCardCustomContent = ({
  localFile,
  alt,
  to,
  size = "small",
  ratio = 1,
  sx,
  children
}) => {
  
  const img = getImage(localFile)
  const imgBox = createRef()
  const vw = useWindowWidth()
  const [minHeight, setMinHeight]  = useState(null)

  console.log(size)

  useEffect(() => {
    setMinHeight( imgBox.current.clientHeight )
  }, [vw, imgBox])

  return (
    <Card to={to} sx={sx}>
      <Box ref={imgBox}>
        <GatsbyImage image={img} alt={alt} />
      </Box>
      <Box sx = { (theme) => ({
        padding: `${theme.spacing(2)}`,
        paddingBottom: `${theme.spacing(4)}`,
        minHeight: minHeight ? `${minHeight / ratio}px` : `auto`,
      })}>
        {children}
      </Box>
    </Card>
  )
}

export default function Card({children, sx, to=null, direction='vertical', onClick}) {
  
  return(
      <Box component="article" sx={sx} onClick={onClick}>
        {to ? (
          <Link to={to}>
            <Wrapper to={to} direction={direction}>{children}</Wrapper>
          </Link>
        ) : (
          <Wrapper direction={direction}>{children}</Wrapper>
        )}
       
      </Box>
  )
};


export function HorizontalCard({children, sx, to}) {
  
  return(
    <Box component="article" sx={sx}>
        <Link to={to}>
        <Box
          sx = { (theme) => ({
            backgroundColor: theme.palette.grey1,
            height: '100%',
            display: {
              xs: 'block',
              md: 'grid'
            },
            gap: theme.spacing(3),
            gridTemplateColumns: {
              md: 'repeat(6, minmax(0, 1fr))',
              xxl: 'repeat(8, minmax(0, 1fr))'
            },
            gridTemplateRows: 'auto',
            transition: 'all 0.25s ease-in-out',
          
            '&:hover':{
              backgroundColor: to ? theme.palette.grey2 : theme.palette.white,
            }
          })}
        >
          {children}
        </Box>
        </Link>
    </Box>
  )
};




