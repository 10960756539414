
import { 
  OPEN_MENU,
  CLOSE_MENU,
  OPEN_SEARCH,
  CLOSE_SEARCH,
} from "./action-types";

/**
 * Action Creators – return action (object wti property type and payload)
 */

/**
 * MENU ACTIONS
 */
export const openMenu = () => ({
  type: OPEN_MENU
})
export const closeMenu = () => ({
  type: CLOSE_MENU
})

/**
 * SEARCH ACTIONS
 */
 export const openSearch = () => ({
  type: OPEN_SEARCH
})
export const closeSearch = () => ({
  type: CLOSE_SEARCH
})
