import React, {useContext} from 'react';
import { SvgIcon, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { appContext } from './../../contexts';
import { openSearch } from './../../contexts/actions';


const SearchSvg = styled(SvgIcon)(
  ({ theme }) => `
    width: 1em;
    height: 1em;
    font-size: 1em;
    & path, & circle {
      stroke: ${theme.palette.primary.darkest};
      stroke-width: 2.5px;
      stroke-linecap: round;
      fill: none;
    }
  `,
);
export const SearchIcon = (props) => (
  <SearchSvg {...props}>
    <circle cx="-1571.7" cy="-188.7" r="7.2" transform="translate(1581 198)"/><path d="m14.9 14.9 7.2 7.2"/>
  </SearchSvg>
)

export default function SearchBtn({sx}) {

  const {setApp} = useContext(appContext)

  return (
    <IconButton aria-label="Search" sx={sx} onClick={(e) => {
      e.preventDefault()
      setApp(openSearch())
    }}>
      <SearchIcon />
    </IconButton>
  )
};
