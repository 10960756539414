import { Transition } from 'react-transition-group';
import React, {useContext, useRef} from 'react';

import SearchContent from './SearchContent';
import { appContext } from './../../contexts';


export default function Search() {
  const {app} = useContext(appContext)
  const nodeRef = useRef(null);

  return (
    <Transition 
      nodeRef={nodeRef} 
      in={app.search} 
      timeout={500}
      unmountOnExit={true}
      mountOnEnter={true}
    >
      {state => (
        <div ref={nodeRef}><SearchContent state={`${state}`} /></div>
      )}
    </Transition>
    
  );
}