import React from 'react';
import { SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSvg = styled(SvgIcon)(
  ({ theme }) => `
    width: 1em;
    height: 1em;
    font-size: 1em;
    line-height: 1;
    display: block;

    & path, & circle {
      fill: ${theme.palette.primary.darkest};
    }
  `,
);


export const MenuExpend = (params) => {
  return (
    <StyledSvg {...params}>
      <path d="m12 19.3-7-12h14l-7 12z"/>
    </StyledSvg>
  )
};

export const MenuLess = (params) => {
  return (
    <StyledSvg {...params}>
      <path d="m12 6 7 12H5l7-12z" />
    </StyledSvg>
  )
};
