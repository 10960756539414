import React from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';

export const Container = (prop) => {
  return (
    <Box 
      sx = { (theme) => ({
        maxWidth: {
          xs: 1100,
          xxl: 1380,
          
        },
        margin: '0 auto',
        width: '100%',
        
      })}

      px={
        {
          xs: 4,
          sm: 4,
          // md: 8,
          // lg: 12,
        }
      }
      {...prop}
    >
      {prop.children}
    </Box>
  )
};

export const GridContainer = (prop) => {
  return (
    <Grid 
      container 
      columnSpacing={{ 
        xs: 4,
        lg: 6,
      }}
      
      alignItems="flex-start"
      {...prop}
    >
      {prop.children}
    </Grid>
  )
};
