/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useContext, useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Box } from "@mui/system"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from '@mui/material/styles';

import { appContext } from "./../contexts"
import { closeSearch } from "./../contexts/actions"

import Header from "./header"
import Footer from "./footer"
import Search from "./search"

import "./../styles/global.scss"

const Layout = ({ children, data, pageContext }) => {

  const {app, setApp} = useContext(appContext)

  const globalInfo = useStaticQuery(graphql`
    query globalInfo {
      wp {
        generalSettings {
          description
          title
        }
      }
      currentIssue: wpIssue(issueFields: {featured: {eq: true}}) {
        title
        slug
        issueFields {
          volume
          season
        }
      }
      issues: allWpIssue {
        nodes {
          title
          slug
          issueFields{
            featured
          }
        }
      }
      categories: allWpCategory(filter: {count: {gt: 0}}) {
        nodes {
          name
          slug
        }
      }
    }
  `)

  useEffect(()  => {
    const html = document.querySelector('html')
    if (app.search) {
      html.classList.add('modal-open')
    } else {
      html.classList.remove('modal-open')
    }
    return () => {
      html.classList.remove('modal-open')
    };
  }, [app.search]);

  const theme = useTheme()
  const lg = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Box 
      sx = { (theme) => ({
        perspective: {
          xs: '1000px',
        },
        backgroundColor: 'var(--grey2)',
      })}
      >

      <Box
        onClick = {() => {
          // console.log('click')
          // console.log(app.search)
          if (app.search) {
            setApp(closeSearch())
          }
        }}
        sx = { (theme) => ({
          transition: `transform ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeOutCubic} 0ms`,
          transform: {
            xs: app.search ? 'translate3d(0px, 100vh, 2.5vh)' : 'translate3d(0px, 0, 0)',
            md: app.search ? 'translate3d(0px, 90vh, 2.5vh)' : 'translate3d(0px, 0, 0)'
          },
          cursor: app.search ? 'pointer' : 'auto',
          position: 'relative',
          zIndex: 10000,
        })}
      >
        <Box
          sx = { (theme) => ({
            transition: `transform ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.slide} 0ms`,
            transform: (!lg && app.menu) ? `translateX(-${theme.mobileMenu.width})` : `translateX(0)`,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: theme.palette.white,
            touchAction: app.search ? 'none' : 'auto',
            pointerEvents: app.search ? 'none' : 'auto',
            zIndex: 10000,
          })}
        
          >
          <Header
            data={data || pageContext}
            globalInfo={globalInfo}
          />
          <main style={{
            flexGrow: 1,
            // pointerEvent: app.menu ? 'none' : 'auto'
          }}>{children}</main>
          <Footer
            globalInfo={globalInfo}
          />
      </Box>
      </Box>

      <Search />
      
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
