import { 
  OPEN_MENU,
  CLOSE_MENU,
  OPEN_SEARCH,
  CLOSE_SEARCH,
 } from "./../actions/action-types";

export default function appReducer(state, action){
  switch (action.type) {
    case OPEN_MENU:
      return {
        ...state,
        menu: true
      }
    case CLOSE_MENU:
      return {
        ...state,
        menu: false
      }
    case OPEN_SEARCH:
      return {
        ...state,
        search: true
      }
    case CLOSE_SEARCH:
      return {
        ...state,
        search: false
      }
    default:
      return {
        ...state,
      } 
  }
}