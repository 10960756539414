import React, { useContext, useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { TransitionGroup } from "react-transition-group";
import { Grow } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { navigate } from "gatsby";

import Card, { CardTitle, CardContent } from "./../Card";
import { appContext } from "./../../contexts";
import { closeSearch } from "./../../contexts/actions";
import SearchField from "./SearchField";
import CloseBtn from "./../header/CloseBtn";

const getQuery = query => `
  query search {
    searchPost(where: {keyword: "${query}"}, first: 20) {
      nodes {
        title
        slug
        categories {
          nodes {
            name
          }
        }
        postFields{
          articles {
            ...on Issue{
              title
              slug
              issueFields{
                volume
                season
              }
            }
          }
        }
        featuredImage {
          node {
            mediaDetails {
              filteredSizes(sizes: ["thumbnail"]){
                name
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;
export default function SearchContent({ state }) {
  const startText = "Start typing and wait for results.";
  const noResultsText = `No luck. Try again.`;

  const { app, setApp } = useContext(appContext);

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  const theme = useTheme();

  const handleSearch = useCallback(
    debounce(input => {
      const queryString = getQuery(input);
      // console.log(queryString)
      axios({
        url: process.env.GATSBY_WP + "/graphql",
        method: "post",
        data: {
          query: queryString,
        },
      })
        .then(result => {
          setLoading(false);
          const data = result.data.data.searchPost;
          setSearchResult(data ? data.nodes : []);
        })
        .catch(e => {
          setLoading(false);
          console.error(e);
        });
    }, 1000),
    []
  );

  /** Trigger when model is closed */
  const handleClose = () => {
    setApp(closeSearch());
    // setValue('')
    // setSearchResult([])
  };

  /** Trigger while typing */
  const handleTyping = input => {
    // Set new typed value
    setValue(input);

    if (input.length > 2) {
      setLoading(true);
      handleSearch(input);
    } else {
      //setSearchResult([])
    }
  };

  const handleEsc = event => {
    if (event.keyCode === 27) {
      setApp(closeSearch());
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEsc);
    return () => document.removeEventListener("keydown", handleEsc);
  }, [app.search]);

  return (
    <Box
      sx={theme => ({
        position: "fixed",
        width: "100%",
        height: "100vh",
        top: 0,
        left: 0,
        overflow: "auto",
        zIndex: -10,
      })}
      className={state}
    >
      <Box
        sx={theme => ({
          maxWidth: 1380,
          margin: "0 auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          transition: "margin 300ms",

          px: {
            xs: 4,
            sm: 8,
          },

          mt: {
            xs: "10vh",
            lg: searchResult?.length > 6 && value.length > 2 ? "5vh" : "35vh",
          },
        })}
      >
        <CloseBtn
          onClick={handleClose}
          sx={{
            // padding:0,
            position: "absolute",
            top: {
              xs: theme.spacing(3),
              lg: "6vh",
            },
            right: {
              xs: theme.spacing(3),
              lg: "6vh",
            },
          }}
        />

        <SearchField
          onChange={input => handleTyping(input)}
          onClose={true && handleClose}
          loading={loading}
          value={value}
        />
        {searchResult === null ? (
          <p>{startText}</p>
        ) : searchResult.length !== 0 && value.length > 2 ? (
          <Box
            sx={theme => ({
              mt: {
                xs: 4,
                sm: 6,
                md: 8,
              },
              pb: {
                xs: 4,
                sm: 6,
                md: 8,
              },
              mb: {
                xs: "12.5vh",
                lg: "25vh",
              },

              "& > div": {
                display: "grid",
                gap: 4,
                gridTemplateColumns: {
                  xs: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(3, minmax(0, 1fr))",
                  md2: "repeat(4, minmax(0, 1fr))",
                  lg: "repeat(6, minmax(0, 1fr))",
                  // xxxl: 'repeat(8, minmax(0, 1fr))'
                },
              },
            })}
          >
            <TransitionGroup>
              {searchResult.map((article, i) => (
                <Grow
                  key={`${article.slug}-${i}`}
                  timeout={{
                    appear: 0,
                    enter: 500,
                    exit: 0,
                  }}
                >
                  <div>
                    <Card
                      sx={theme => ({
                        height: "100%",
                        cursor: "pointer",
                      })}
                      onClick={() => {
                        navigate(
                          `/${article.postFields.articles.slug}/${article.slug}`
                        );
                        handleClose();
                      }}
                    >
                      <LazyLoadImage
                        alt={article.title}
                        height={150}
                        width={150}
                        src={
                          article.featuredImage?.node.mediaDetails
                            .filteredSizes[0].sourceUrl
                        }
                        style={{
                          display: "block",
                          width: "100%",
                          height: "auto",
                        }}
                      />
                      <CardContent>
                        <CardTitle size={"small"}>{article.title}</CardTitle>
                      </CardContent>
                    </Card>
                  </div>
                </Grow>
              ))}
            </TransitionGroup>
          </Box>
        ) : (
          <p>{value.length > 2 ? noResultsText : startText}</p>
        )}
      </Box>
    </Box>
  );
}
