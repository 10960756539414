import React from 'react';
import {Box, styled} from '@mui/system';
import { Grid } from '@mui/material';
import { Link as DefaultLink } from 'gatsby';

import { Container, GridContainer } from './../../grid';
import Logo from './../elements/Logo';


const Link = styled(DefaultLink)(
  ({ theme }) => `
    color: var(--grey5);
    font-family: var(--nexus-type);
    font-size: var(--fs-xs);
    font-weight: bold;
    display: block;
    padding: 0.25rem 0;
    transition: all 0.25s ease-in-out;

    ${theme.breakpoints.up("lg")} {
      font-size: var(--fs-s);
    }

    &:hover {
      color: var(--grey6);
      text-decoration: underline;
    }
`)

const Copyright = styled('small')(
  ({ theme }) => `
    color: var(--grey5);
    font-family: var(--nexus-sans);
    font-size: var(--fs-xs);

    ${theme.breakpoints.up("lg")} {
      font-size: var(--fs-s);
    }

`)

export default function Footer({globalInfo}){
  return (
    <Box component={'footer'} sx = { (theme) => ({
      mt: 9,
      py: 6,
      bgcolor: theme.palette.grey3
    })}>
      <Container>
        <GridContainer>
          {/* 1st column – logo */}
          <Grid item xs={12} mb={7} mt={2} md={4}>
            <Logo sx={{
              width: {
                xs: '70%',
                lg: '90%'
              },
              maxWidth: '300px'
            }} variant="negative" />
          </Grid>
          
          <Grid 
            item xs={12} 
            md={8}
            sx = { (theme) => ({
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(2, minmax(0, 1fr))',
                md2: 'repeat(4, auto)'
              },
              gridTemplateRows: {
                xs: 'repeat(2, minmax(0, 1fr))',
                md2: 'auto'
              },
              gap: 4,
              
            })}
          >
            {/* 2nd column */}
            <Box mb={7}>
              <Link to={`/${globalInfo.currentIssue.title}`}>Latest Issue</Link>
              <Link to={`/events`}>Events</Link>
              <Link to={`/about`}>About</Link>
            </Box>
            {/* 3th column */}
            <Box mb={7}>
              <Link to={`/editorial`}>Editorial</Link>
              <Link to={`/interview`}>Interview</Link>
              <Link to={`/architectural-icon`}>Architectural Icon</Link>
              <Link to={`/awards`}>Piranesi Awards</Link>
              <Link to={`/architecture`}>New Architecture</Link>
              <Link to={`/design`}>Design</Link>
            </Box>
            {/* 4th column */}
            <Box mb={7}>
              <Link to="/contact">Contact</Link>
              <Link to="/order">Order</Link>
              <Link to="/sponsors">Sponsors</Link>
            </Box>
            {/* 5th column */}
            <Box mb={7}>
              <Link to="/legal-notice">Legal notice</Link>
              <Link to="/policy">Cookie policy</Link>
              <Link to="/impressum">Impressum</Link>
            </Box>
          </Grid>
        </GridContainer>
        <Box sx = { (theme) => ({
          mt: {
            lg: 11
          },
          textAlign: {
            md: 'center'
          }
        })}>
          <Copyright>{`© 1992–${new Date().getFullYear()} Ustanova Fundacija Piranesi, all right reserved.`}</Copyright>
        </Box>
      </Container>
    </Box>
  )
};
 