import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import useTheme from "@mui/material/styles/useTheme";

import { appContext } from "./../../contexts";
import { openMenu, closeMenu } from "./../../contexts/actions";

import { Twirl as Hamburger } from "./../../../plugins/hamburger/src/Twirl";

export default function MenuBtn() {
  const { app, setApp } = useContext(appContext);
  const theme = useTheme();
  return (
    <IconButton
      sx={theme => ({
        p: { xs: 2, sm: 2, xxxl: 3 },
        ml: { xs: 0, lg: 3 },
        mr: "-1rem",
        position: "relative",
        zIndex: 300,
        backgroundColor: "transparent",
      })}
      aria-label={"Menu"}
      onClick={e => {
        // console.log('click')
        setApp(app.menu ? closeMenu() : openMenu());
        // setOpen(open ? false : true)
      }}
    >
      <Hamburger
        toggled={app.menu}
        toggle={e => setApp(e ? openMenu() : closeMenu())}
        size={30}
        color={theme.palette.primary.darkest}
        label="Odpri meni"
        rounded
        easing={theme.transitions.easing.easeOutBack}
        duration={0.4}
        direction={"left"}
        distance={"lg"}
      />
    </IconButton>
  );
}
