
export const getAuthorSurname = (author) => {
  let s = author.split(' ')[1].toLowerCase()
  if (s[s.length - 1] === ',') {
    s = s.slice(0, s.length - 1)
  }
  return s
}

export const isBrowser = typeof window !== "undefined"

export const isEmpty = value => {
  if (typeof value === 'string'){
    return !value.trim().length;
  } else {
    return !value
  }
}
